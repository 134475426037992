<template>
  <div>
    <section-title
      class="content__section-title"
      container-class="content__section-title__container"
      icon="fas fa-user-edit"
      title="Selección de Servicio(s)"
      title-class="content__section-title__info"
    />

    <div class="bg-secondary-9 rounded px-3 px-md-4 pt-4 pb-2 mb-4">
      <div class="row">
        <div
          v-for="(service, idx) in services"
          :key="`service-${idx}`"
          class="col-12 col-md-4 mb-4"
        >
          <service-bagde
            :service="service"
            @selected="selectServiceHandler(idx)"
          />
        </div>
      </div>
      <span class="d-block font-14 mt-3">
        <em class="far fa-info-circle mr-2 color-yellow font-18" />
        Puede seleccionar más de un (1) servicio.
      </span>
    </div>

    <section-title has-line title="Servicio(s) Seleccionado(s)" />

    <custom-result-not-found
      v-if="selectedServices.length === 0"
      text="No posee servicios seleccionados"
    />

    <div
      v-for="(service, index) in selectedServices"
      :key="`selected-service-${index}`"
      class="mb-3"
    >
      <service-container
        :icon="service.icon"
        :title="service.label"
        @delete="deleteHandler(index, service)"
      >
        <component
          :is="service.component"
          :ref="`${service.component}-${index}`"
          :data="service[`requestService${service.component}`]"
          :selected-evaluation-disciplines="
            selectedEvaluationDisciplines(service)
          "
          :selected-therapy-disciplines="selectedTherapyDisciplines(service)"
          :service-index="index"
        />
      </service-container>
    </div>
  </div>
</template>

<script>
import SectionTitle from 'SectionTitle';
import alert from 'utils/mixins/alert.mixin';
import ServiceBagde from '@/views/students/provisional-remedy/components/ServiceBagde.vue';
import ServiceContainer from '@/views/students/provisional-remedy/components/ServiceContainer.vue';
import CustomResultNotFound from 'custom-result-not-found';
import ServiceTypes, {
  ServiceTypeComponentObj,
} from '@/utils/constants/provisional-remedy/serviceTypes';
import {
  Equipment,
  Barrier,
  Evaluation,
  Assistant,
  Therapy,
  Transportation,
} from '@/views/students/provisional-remedy/components';
import {
  removeRequestService as _removeRequestService,
  validateUniqueService as _validateUniqueService,
} from '@/modules/provisional-remedy/api';

const MultipleSelectionService = [
  ServiceTypes.Therapy,
  ServiceTypes.Evaluation,
];

export default {
  name: 'Step2',
  mixins: [alert],
  components: {
    SectionTitle,
    CustomResultNotFound,
    ServiceBagde,
    ServiceContainer,
    Equipment,
    Barrier,
    Evaluation,
    Assistant,
    Therapy,
    Transportation,
  },
  props: {
    academicYearId: {
      type: Number,
      default: () => null,
    },
    studentSie: {
      type: Number,
      default: () => null,
    }
  },
  data: () => ({
    services: [
      {
        ...ServiceTypeComponentObj.Assistant,
        active: false,
        complete: false,
      },
      {
        ...ServiceTypeComponentObj.Equipment,
        active: false,
        complete: false,
      },
      {
        ...ServiceTypeComponentObj.Evaluation,
        active: false,
        complete: false,
      },
      {
        ...ServiceTypeComponentObj.Therapy,
        active: false,
        complete: false,
      },
      {
        ...ServiceTypeComponentObj.Transportation,
        active: false,
        complete: false,
      },
    ],
    selectedServices: [],
  }),
  computed: {
    canSelectMultiple() {
      return (serviceId) => MultipleSelectionService.includes(serviceId);
    },
    selectedEvaluationDisciplines() {
      return ({ serviceId }) => {
        if (serviceId != ServiceTypes.Evaluation) return null;
        const disciplines = [];
        this.selectedServices.forEach((s, i) => {
          const evaluationComponent = this.$refs[`${s.component}-${i}`];
          if (
            s.serviceId == ServiceTypes.Evaluation &&
            evaluationComponent?.length
          ) {
            const component = evaluationComponent[0];
            disciplines.push(component?.model?.discipline?.name);
          }
        });
        return disciplines;
      };
    },
    selectedTherapyDisciplines() {
      return ({ serviceId }) => {
        if (serviceId != ServiceTypes.Therapy) return null;
        const disciplines = [];
        this.selectedServices.forEach((s, i) => {
          const therapyComponent = this.$refs[`${s.component}-${i}`];
          if (s.serviceId == ServiceTypes.Therapy && therapyComponent?.length) {
            const model = therapyComponent[0].getModel();
            disciplines.push(model.disciplineTypeId);
          }
        });
        return disciplines;
      };
    },
    getServiceModel() {
      return (service, idx, getCatalogOption = false) => {
        const component = this.$refs[`${service.component}-${idx}`][0]
        return {
          [`requestService${service.component}`]: {
            ...component?.getModel(getCatalogOption),
            disciplineType: component?.model?.discipline,
          }
        };
      };
    },
  },
  methods: {
    setServiceObj(serviceType) {
      let service = {};
      switch (serviceType) {
        case ServiceTypes.Assistant:
          service = ServiceTypeComponentObj.Assistant;
          break;
        case ServiceTypes.Barriers:
          service = ServiceTypeComponentObj.Barriers;
          break;
        case ServiceTypes.Equipment:
          service = ServiceTypeComponentObj.Equipment;
          break;
        case ServiceTypes.Evaluation:
          service = ServiceTypeComponentObj.Evaluation;
          break;
        case ServiceTypes.Therapy:
          service = ServiceTypeComponentObj.Therapy;
          break;
        case ServiceTypes.Transportation:
          service = ServiceTypeComponentObj.Transportation;
          break;
      }
      return service;
    },
    selectServiceHandler(idx) {
      const selectedService = this.services[idx];
      const isAlreadySelected = this.selectedServices.some(
        (s) => s.serviceId == selectedService.serviceId
      );
      if (
        !isAlreadySelected ||
        this.canSelectMultiple(this.services[idx].serviceId)
      ) {
        this.selectedServices = this.selectedServices.map((service, i) => ({
          ...service,
          ...this.getServiceModel(service, i, true),
        }));
        this.selectedServices.push(selectedService);
      }
      this.setBadgeState();
    },
    setBadgeState() {
      this.services = this.services.map((s) => {
        const selectedAmount = this.selectedServices.filter(
          (sv) => sv.serviceId == s.serviceId
        ).length;
        return {
          ...s,
          complete: Boolean(selectedAmount),
          amount: this.canSelectMultiple(s.serviceId) ? selectedAmount : null,
        };
      });
    },
    async deleteHandler(index, service) {
      let proceed = true;

      //remove added service
      if (service?.requestServiceId) {
        proceed = await this.ShowCancelModal(
          'Confirmación',
          `Usted cuenta con información documentada, ¿está seguro que desea eliminar el servicio ${service.label ||
            ''}?`,
          'Sí',
          'No'
        );
        if (proceed) {
          //validate unique
          const isUnique = (
            await _validateUniqueService(
              service.requestId,
              service.requestServiceId
            )
          ).data;

          //remove service
          await _removeRequestService(
            service.requestId,
            service.requestServiceId
          );

          //if is unique go to home
          if (isUnique) {
            this.$router.push({
              name: 'provisional-remedy',
            });
            return;
          }
        }
      }
      if (proceed) {
        this.selectedServices.splice(index, 1);
        this.setBadgeState();
        this.ShowToast(
          '',
          'El servicio ha sido eliminado exitosamente.',
          'success'
        );
      }
    },
    async saveStep() {
      const params = [];
      let validModel = true;

      for (const [i, s] of this.selectedServices.entries()) {
        if (
          [ServiceTypes.Therapy].includes(s.serviceId) &&
          !(await this.$refs[`${s.component}-${i}`][0].validateService())
        ) {
          validModel = false;
        }

        params.push({
          academicYearId: this.academicYearId,
          studentSie: this.studentSie,
          requestServiceId: s.requestServiceId,
          serviceTypeId: s.serviceId,
          ...this.getServiceModel(s, i),
        });
      }

      if(!validModel) {
        this.ShowToast(
          '',
          'Favor de completar la información solicitada para poder continuar.',
          'error'
        );
        return;
      }

      this.$emit('save-step2-data', params);
    },
    async validate() {
      if (this.selectedServices.length == 0) {
        return {
          valid: false,
          msg: 'Favor seleccionar al menos un servicio.',
        };
      }

      let invalid = false;
      for (const [i, s] of this.selectedServices.entries()) {
        if (!(await this.$refs[`${s.component}-${i}`][0].validateService()))
          invalid = true;
      }
      return { valid: !invalid };
    },
  },
};
</script>
