var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-container"},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center cursor-pointer",on:{"click":_vm.onSelectOption}},[_c('span',{staticClass:"fa-stack fa-2x nav-container__icon-container"},[_c('i',{staticClass:"fas fa-circle fa-stack-2x",class:{
          'nav-container--inactive': !_vm.active,
          'nav-container--active': _vm.option.active,
          'nav-container--completed': _vm.option.completed,
        }}),_c('span',{staticClass:"fa-stack-1x color-white font-weight-bold"},[(_vm.option.completed)?_c('i',{staticClass:"fas fa-check"}):_c('span',{class:{ 'color-gray-2': !_vm.active }},[_vm._v(" "+_vm._s(_vm.position + 1)+" ")])])]),_c('span',{staticClass:"font-18 font-weight-bold mt-2 nav-container__title d-block",class:{
        'color-gray': _vm.active,
        'nav-container--inactive-label': !_vm.active,
        'nav-container--completed': _vm.option.completed,
      }},[_vm._v(" "+_vm._s(_vm.option.title)+" ")])]),_c('div',{staticClass:"w-100 mt-md-4 mt-3 border__bottom--secondary-12",class:_vm.active ? 'active-border' : 'inactive-border'})])}
var staticRenderFns = []

export { render, staticRenderFns }