<template>
  <div>
    <section-title
      class="content__section-title"
      container-class="content__section-title__container"
      icon="far fa-user-alt"
      title="Localización de Estudiante"
      title-class="content__section-title__info"
    />

    <section-title
      has-line
      title="Verifique y confirme la ubicación del estudiante"
    />

    <validation-observer ref="formRef">
      <div class="row mx-0">
        <div class="col-12 col-md-4 mb-4">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-drop-down
              v-model="model.place"
              :clearable="false"
              custom-error-msg="El lugar es requerido."
              :error="errors[0]"
              fieldkey="id"
              fieldname="name"
              label="Lugar:"
              :options="options.places"
              placeholder="Seleccione"
              @input="getDistricts()"
            />
          </validation-provider>
        </div>
        <div class="col-12 col-md-4 mb-4">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-drop-down
              v-model="model.region"
              :clearable="false"
              custom-error-msg="La región es requerida."
              :disabled="model.place == null"
              :error="errors[0]"
              fieldkey="id"
              fieldname="name"
              label="Región:"
              :options="options.regions"
              placeholder="Seleccione"
              @input="getDistricts()"
            />
          </validation-provider>
        </div>
        <div class="col-12 col-md-4 mb-4">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-drop-down
              v-model="model.district"
              :clearable="false"
              custom-error-msg="El distrito es requerido."
              :disabled="!Boolean(options.districts.length)"
              :error="errors[0]"
              fieldkey="id"
              fieldname="name"
              label="Distrito:"
              :options="options.districts"
              placeholder="Seleccione"
              @input="getMunicipalities()"
            />
          </validation-provider>
        </div>
        <div class="col-12 col-md-4 mb-4">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-drop-down
              v-model="model.municipality"
              :clearable="false"
              custom-error-msg="El municipio es requerido."
              :disabled="!Boolean(options.municipalities.length)"
              :error="errors[0]"
              fieldkey="id"
              fieldname="name"
              label="Municipio:"
              :options="options.municipalities"
              placeholder="Seleccione"
              @input="getSchools()"
            />
          </validation-provider>
        </div>
        <div class="col-12 col-md-4" :class="{ 'col-md-8': !canShowOther }">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-drop-down
              v-model="model.school"
              :clearable="false"
              custom-error-msg="La escuela es requerida."
              :disabled="!Boolean(options.schools.length)"
              :error="errors[0]"
              fieldkey="id"
              fieldname="name"
              label="Escuela:"
              :options="options.schools"
              placeholder="Seleccione"
            />
          </validation-provider>
        </div>
        <div v-if="canShowOther" class="col-12 col-md-4">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-input
              v-model="model.other"
              custom-error-msg="Nombre de Escuela es requerido."
              :error="errors[0]"
              label="Nombre de Escuela:"
              :placeholder="'Nombre de Escuela'"
            />
          </validation-provider>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import CustomDropDown from 'custom-drop-down';
import SectionTitle from 'SectionTitle';
import {
  getLocationTypes,
  getRegions,
  getDistricts,
  getMunicipalities,
  getMunicipalitySchools,
} from '@/services/location/locationApi';
import CustomInput from 'CustomInput';
import SchoolTypes from '@/utils/constants/student/schoolTypes';

export default {
  name: 'Step1',
  components: {
    CustomDropDown,
    SectionTitle,
    CustomInput,
  },
  props: {
    locationData: {
      type: Object,
      default: () => {},
    },
    academicYearId: {
      type: Number,
      default: () => 0,
    },
    studentSie: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    model: {
      place: null,
      region: null,
      district: null,
      municipality: null,
      school: null,
      other: null,
    },
    options: {
      places: [],
      regions: [],
      districts: [],
      municipalities: [],
      schools: [],
    },
  }),
  watch: {
    locationData: {
      immediate: true,
      async handler(newVal) {
        if (newVal && Object.keys(newVal).length) {
          this.model = {
            place: newVal.locationType,
            district: newVal.district,
            municipality: newVal.municipality,
            school: newVal.school,
            region: newVal.region,
            other: newVal.privateLocationName,
          };
          await this.getCollections();
          await Promise.all([
            this.getDistricts(true),
            this.getMunicipalities(true),
            this.getSchools(true),
          ]);
        }
      },
    },
  },
  computed: {
    canShowOther() {
      const valid = [
        SchoolTypes.CareCenter,
        SchoolTypes.PrivateSchool,
        SchoolTypes.HeadStart,
      ];
      return valid.includes(this.model.place?.id);
    },
  },
  methods: {
    async getCollections() {
      const { data: regions } = await getRegions();
      const { data: places } = await getLocationTypes();
      this.options = {
        ...this.options,
        places,
        regions,
      };
    },
    async getDistricts(notReset) {
      const region = this.model.region;
      const schoolType = this.model.place;

      if (region && schoolType) {
        const { data } = await getDistricts({
          schoolTypes: schoolType.id,
          regionId: region.id,
          academicYearId: this.academicYearId,
        });
        this.options.districts = data;

        if (notReset) return;

        this.model = {
          ...this.model,
          district: null,
          school: null,
          municipality: null,
        };
        this.options = {
          ...this.options,
          schools: [],
          municipalities: [],
        };
        await this.$refs.formRef.reset();
      }
    },
    async getMunicipalities(notReset) {
      if (this.model.district && this.model.region) {
        const params = {
          academicYearId: this.academicYearId,
          regionId: this.model.region.id,
          schoolTypes: this.model.place.id,
          districtId: this.model.district.id,
        };
        const { data } = await getMunicipalities(params);
        this.options.municipalities = data;

        if (notReset) return;

        this.model = {
          ...this.model,
          school: null,
          municipality: null,
        };

        this.options = {
          ...this.options,
          schools: [],
        };
        await this.$refs.formRef.reset();
      }
    },
    async getSchools(notReset) {
      const model = { ...this.model };
      const validModel = () =>
        model.place && model.region && model.district && model.municipality;

      if (validModel()) {
        const params = {
          academicYearId: this.academicYearId,
          schoolTypes: model.place.id,
          districtId: model.district.id,
          municipalityId: model.municipality.id,
          regionId: model.region.id,
        };
        const { data } = await getMunicipalitySchools(params);
        this.options.schools = data;

        if (notReset) return;
        this.model.school = null;
        await this.$refs.formRef.reset();
      }
    },
    saveStep() {
      const params = {
        studentSieId: this.studentSie,
        locationTypeId: this.model.place?.id,
        districtId: this.model.district?.id,
        municipalityId: this.model.municipality?.id,
        regionId: this.model.region?.id,
        locationSchoolId: this.model.school?.id,
        other: this.model.other,
      };
      this.$emit('save-step1-data', params);
    },
    async validate() {
      const valid = await this.$refs.formRef.validate();
      return { valid };
    },
  },
};
</script>

<style lang="scss" scoped></style>
