var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section-title',{staticClass:"content__section-title",attrs:{"container-class":"content__section-title__container","icon":"far fa-user-alt","title":"Localización de Estudiante","title-class":"content__section-title__info"}}),_c('section-title',{attrs:{"has-line":"","title":"Verifique y confirme la ubicación del estudiante"}}),_c('validation-observer',{ref:"formRef"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-12 col-md-4 mb-4"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-drop-down',{attrs:{"clearable":false,"custom-error-msg":"El lugar es requerido.","error":errors[0],"fieldkey":"id","fieldname":"name","label":"Lugar:","options":_vm.options.places,"placeholder":"Seleccione"},on:{"input":function($event){return _vm.getDistricts()}},model:{value:(_vm.model.place),callback:function ($$v) {_vm.$set(_vm.model, "place", $$v)},expression:"model.place"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 mb-4"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-drop-down',{attrs:{"clearable":false,"custom-error-msg":"La región es requerida.","disabled":_vm.model.place == null,"error":errors[0],"fieldkey":"id","fieldname":"name","label":"Región:","options":_vm.options.regions,"placeholder":"Seleccione"},on:{"input":function($event){return _vm.getDistricts()}},model:{value:(_vm.model.region),callback:function ($$v) {_vm.$set(_vm.model, "region", $$v)},expression:"model.region"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 mb-4"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-drop-down',{attrs:{"clearable":false,"custom-error-msg":"El distrito es requerido.","disabled":!Boolean(_vm.options.districts.length),"error":errors[0],"fieldkey":"id","fieldname":"name","label":"Distrito:","options":_vm.options.districts,"placeholder":"Seleccione"},on:{"input":function($event){return _vm.getMunicipalities()}},model:{value:(_vm.model.district),callback:function ($$v) {_vm.$set(_vm.model, "district", $$v)},expression:"model.district"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 mb-4"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-drop-down',{attrs:{"clearable":false,"custom-error-msg":"El municipio es requerido.","disabled":!Boolean(_vm.options.municipalities.length),"error":errors[0],"fieldkey":"id","fieldname":"name","label":"Municipio:","options":_vm.options.municipalities,"placeholder":"Seleccione"},on:{"input":function($event){return _vm.getSchools()}},model:{value:(_vm.model.municipality),callback:function ($$v) {_vm.$set(_vm.model, "municipality", $$v)},expression:"model.municipality"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4",class:{ 'col-md-8': !_vm.canShowOther }},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-drop-down',{attrs:{"clearable":false,"custom-error-msg":"La escuela es requerida.","disabled":!Boolean(_vm.options.schools.length),"error":errors[0],"fieldkey":"id","fieldname":"name","label":"Escuela:","options":_vm.options.schools,"placeholder":"Seleccione"},model:{value:(_vm.model.school),callback:function ($$v) {_vm.$set(_vm.model, "school", $$v)},expression:"model.school"}})]}}])})],1),(_vm.canShowOther)?_c('div',{staticClass:"col-12 col-md-4"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-input',{attrs:{"custom-error-msg":"Nombre de Escuela es requerido.","error":errors[0],"label":"Nombre de Escuela:","placeholder":'Nombre de Escuela'},model:{value:(_vm.model.other),callback:function ($$v) {_vm.$set(_vm.model, "other", $$v)},expression:"model.other"}})]}}],null,false,1649458987)})],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }