<template>
  <div
    class="service-container"
    :class="{ 'service-container--active': service.active }"
    @click="clickHandler"
  >
    <div class="d-flex align-items-center">
      <i class="service-icon" :class="service.icon" />
      <span class="label">{{ service.label }}</span>
    </div>
    <transition name="fade">
      <span v-if="showComplete && service.amount" class="fa-stack">
        <i class="far fa-circle fa-stack-2x color-green" />
        <span class="fa-stack-1x font-weight-bold color-dark-gray">
          {{ service.amount }}
        </span>
      </span>
      <i
        v-else-if="showComplete"
        class="far fa-check-circle color-green complete-icon"
      />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ServiceBagde',
  components: {},
  props: {
    service: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    showComplete() {
      return this.service.complete && !this.service.active;
    },
  },
  methods: {
    clickHandler() {
      this.$emit('selected');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/colors.scss';

.service-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1rem 1rem 1rem 2.3rem;
  border-radius: 50rem;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  background: map-get($colors, 'white');
  .service-icon {
    color: map-get($colors, 'yellow');
    font-size: 2rem;
    margin-right: 2rem;
  }
  .label {
    font-size: 1.125rem;
    font-weight: 500;
  }
  .complete-icon {
    font-size: 1.9rem;
  }
  &--active {
    background: map-get($colors, 'green');
    color: map-get($colors, 'white');
    .service-icon {
      color: map-get($colors, 'white');
    }
  }
  div {
    transition: background-color 0.5s, color 0.1s;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
