<template>
  <div class="nav-container">
    <div
      class="d-flex flex-column justify-content-center align-items-center cursor-pointer"
      @click="onSelectOption"
    >
      <span class="fa-stack fa-2x nav-container__icon-container">
        <i
          class="fas fa-circle fa-stack-2x"
          :class="{
            'nav-container--inactive': !active,
            'nav-container--active': option.active,
            'nav-container--completed': option.completed,
          }"
        />
        <span class="fa-stack-1x color-white font-weight-bold">
          <i v-if="option.completed" class="fas fa-check" />
          <span v-else :class="{ 'color-gray-2': !active }">
            {{ position + 1 }}
          </span>
        </span>
      </span>
      <span
        class="font-18 font-weight-bold mt-2 nav-container__title d-block"
        :class="{
          'color-gray': active,
          'nav-container--inactive-label': !active,
          'nav-container--completed': option.completed,
        }"
      >
        {{ option.title }}
      </span>
    </div>
    <div
      class="w-100 mt-md-4 mt-3 border__bottom--secondary-12"
      :class="active ? 'active-border' : 'inactive-border'"
    />
  </div>
</template>

<script>
export default {
  name: 'NavigationItem',
  props: {
    option: {
      type: Object,
      default: () => {},
    },
    position: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    active() {
      return this.option.completed || this.option.active;
    },
  },
  methods: {
    onSelectOption() {
      if (this.option.completed) {
        this.$emit('on-selected-option', this.index);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/variables/colors.scss';

.nav-container {
  .active-border {
    border-top: 5px solid map-get($colors, 'green');
  }
  .inactive-border {
    border-top: 4px solid transparent;
  }
  &--active {
    color: map-get($colors, 'primary-2') !important;
  }
  &--completed {
    color: map-get($colors, 'green') !important;
  }
  &--inactive {
    color: map-get($colors, 'secondary-9') !important;
  }
  &--inactive-label {
    font-weight: normal !important;
    color: map-get($colors, 'gray-2') !important;
  }
}

@media only screen and (max-width: 768px) {
  .nav-container {
    &__icon-container {
      font-size: 1.3rem;
    }
    &__title {
      font-size: 0.8rem !important;
    }
  }
}
</style>
