<template>
  <div>
    <section-title
      class="content__section-title"
      container-class="content__section-title__container"
      icon="fas fa-check"
      title="Información de Confirmación"
      title-class="content__section-title__info"
    />
    <validation-observer ref="confirmRef">
      <section-title has-line title="Comentario" />
      <validation-provider v-slot="{ errors }" rules="required">
        <custom-text-area
          id="comment"
          v-model="model.comment"
          class="equipment__text-area"
          :count-classes="['d-block', 'small-counter', 'text-right']"
          custom-error-msg="Favor escriba un comentario."
          :error="errors[0]"
          :maxlength="3000"
          name="comment"
          show-char-count
        />
      </validation-provider>

      <icon-view
        gradient
        icon="far fa-check-circle color-orange"
        icon-background="white"
        icon-size="2rem"
        title="Confirmación de Información"
        variant="custom-icon-view"
      />
      <validation-provider v-slot="{ errors }" rules="required">
        <custom-checkbox-single-option
          v-model="model.confirmFlag"
          class="confirm__checkbox"
          custom-error-msg="Favor marcar la confirmación."
          :error="errors[0]"
          option-text="<b>Confirmo información sometida</b>"
          title="Certifico que toda la información documentada está correcta."
        />
      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>
import SectionTitle from 'SectionTitle';
import CustomTextArea from 'CustomTextArea';
import CustomCheckboxSingleOption from 'custom-checkbox-single-option';
import IconView from 'icon-view';

export default {
  name: 'Step3',
  components: {
    SectionTitle,
    CustomTextArea,
    CustomCheckboxSingleOption,
    IconView,
  },
  props: {
    finishCommet: {
      type: String,
      default: () => null,
    },
  },
  data: () => ({
    model: {
      confirmFlag: null,
      comment: null,
    },
  }),
  watch: {
    finishCommet: {
      immediate: true,
      handler(value) {
        if (value) this.model.comment = value;
      },
    },
  },
  methods: {
    saveStep() {
      const params = {
        addtionalComments: this.model.comment || '',
      };
      this.$emit('save-step3-data', params);
    },
    validate() {
      const valid = this.$refs.confirmRef.validate();
      return { valid };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .download-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    width: 30%;
    i {
      font-size: 2rem;
      margin-right: 2rem !important;
    }
  }
  .confirm__checkbox p {
    margin: 0;
  }
  .custom-icon-view {
    margin-bottom: 1rem;
    .dot {
      width: 3rem;
      height: 3rem;
    }
    .font-16 {
      font-size: 2rem !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  ::v-deep {
    .download-btn {
      width: 100%;
    }
    .custom-icon-view {
      .font-16 {
        font-size: 1.2rem !important;
      }
    }
  }
}
</style>
