<template>
  <div>
    <page-header
      :page="headerConfig"
      rigth-side-class="col-12 col-md-4"
    />
    <div class="d-flex justify-content-around">
      <div
        v-for="(item, idx) of steps"
        :key="`item-${idx}`"
        :class="{ 'w-100': !isFinalStep(idx) }"
      >
        <navigation-item
          v-if="!isFinalStep(idx)"
          :option="item"
          :position="idx"
          @on-selected-option="onSelectedOptionHandler"
        />
      </div>
    </div>
    <div class="py-5 px-4 border__bottom--secondary-12 content">
      <keep-alive>
        <component
          :is="currentStep"
          ref="currentStepRef"
          :academic-year-id="requestInfo.academicYearId"
          :finish-commemt="requestInfo.additionalComments"
          :location-data="step1Data"
          :request-id="requestId"
          :request-number="requestInfo.requestNumber"
          :student-sie="studentSieId"
          @display-alert="alertHandler"
          @save-step1-data="step1Payload = $event"
          @save-step2-data="step2Payload = $event"
          @save-step3-data="step3Payload = $event"
        />
      </keep-alive>
    </div>
    <div class="d-flex justify-content-end align-items-center actions">
      <template v-if="isFinalStep(currentStepIndex)">
        <action-button
          i="far fa-arrow-alt-circle-right font-18 mr-2"
          text="Salir"
          variant="success action-btn m-0"
          @click="onCancelHandler"
        />
      </template>
      <template v-else>
        <action-button
          i="fa fa-times-circle font-18 mr-2"
          text="Salir"
          variant="danger action-btn"
          @click="onCancelHandler"
        />
        <action-button
          v-if="showBackBtn"
          i="far fa-arrow-alt-circle-left font-18 mr-2"
          text="Regresar"
          variant="outline-success action-btn"
          @click="onGoBackHandler"
        />
        <action-button
          i="far fa-arrow-alt-circle-right font-18 mr-2"
          :text="nextBtn ? 'Continuar' : 'Finalizar'"
          variant="success action-btn m-0"
          @click="onNextHandler"
        />
      </template>
    </div>
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import ActionButton from 'ActionButton';
import NavigationItem from '@/views/students/provisional-remedy/components/NavigationItem.vue';
import {
  Step1,
  Step2,
  Step3,
  FinalStep,
} from '@/views/students/provisional-remedy/steps';
import alert from 'utils/mixins/alert.mixin';
import {
  getRequestInfo as _getRequestInfo,
  removeRequest as _removeRequest,
  saveRequest as _saveRequest,
  saveOrUpdateServices as _saveOrUpdateServices,
  finishRequest as _finishRequest,
} from '@/modules/provisional-remedy/api';
import StepsIndex from '@/views/students/provisional-remedy/constants/requestStepsIndex';
import RpRequestStatus from '@/utils/constants/provisional-remedy/requestStatus.js';

export default {
  name: 'ProvisionalRemedyRequest',
  components: {
    PageHeader,
    ActionButton,
    NavigationItem,
    Step1,
    Step2,
    Step3,
    FinalStep,
  },
  mixins: [alert],
  data: () => ({
    currentStepIndex: 0,
    steps: [
      {
        title: 'Paso 1',
        component: 'Step1',
        active: true,
        completed: false,
      },
      {
        title: 'Paso 2',
        component: 'Step2',
        active: false,
        completed: false,
      },
      {
        title: 'Paso 3',
        component: 'Step3',
        active: false,
        completed: false,
      },
      {
        component: 'FinalStep',
      },
    ],
    step1Data: {},
    requestInfo: {},
    step1Payload: {},
    step2Payload: {},
    step3Payload: {},
  }),
  computed: {
    studentSieId() {
      return Number(this.$route.params.studentSieId);
    },
    requestId() {
      return Number(this.$route.params.requestId) || null;
    },
    stepIdx() {
      return Number(this.$route.params.stepIndex) || null;
    },
    headerConfig() {
      return {
        title: 'Remedio Provisional',
        icon: 'fas fa-file-contract',
        routes: ['Inicio', 'Remedio Provisional'],
      };
    },
    currentStep() {
      return this.steps[this.currentStepIndex].component;
    },
    isFinalStep() {
      return (idx) => idx == StepsIndex.END;
    },
    nextBtn() {
      return this.currentStepIndex < StepsIndex.CONFIRM_INFO;
    },
    showBackBtn() {
      return this.currentStepIndex > StepsIndex.STUDENT_LOCATION;
    },
  },
  async created() {
    await this.getRequestInfo();
  },
  beforeRouteUpdate(to, from, next) {
    this.setCurrentRequestTab(to, next);
  },
  methods: {
    async getRequestInfo() {
      const { data } = await _getRequestInfo(this.requestId, this.studentSieId);

      this.step1Data = data.studentLocation;
      this.requestInfo = data.request;
    },
    onSelectedOptionHandler(index) {
      this.currentStepIndex = index;
      this.setActiveOption(index);
    },
    setActiveOption(optionIndex) {
      this.steps = this.steps.map((item, index) => ({
        ...item,
        active: optionIndex === index,
        completed: this.currentStepIndex > index,
      }));
    },
    setCompleteOption(optionIndex) {
      this.steps = this.steps.map((item, index) => {
        if (optionIndex === index) {
          item.completed = true;
        }
        item.active = optionIndex + 1 === index;
        return item;
      });
    },
    onGoBackHandler() {
      this.currentStepIndex -= 1;
      this.setActiveOption(this.currentStepIndex);
    },
    async onCancelHandler() {
      if(this.currentStepIndex === StepsIndex.END) {
        this.$router.push({ name: 'provisional-remedy' });
        return;
      }
      
      const confirm = await this.ShowCancelModal(
        'Confirmación',
        `Al regresar a la pantalla anterior su solicitud será eliminada, ¿desea continuar?`,
        'Sí',
        'No'
      );

      if (!confirm) return;

      const isRequestInProgress = this.requestInfo.statusId === RpRequestStatus.InProcess;
      
      if(!isRequestInProgress || !this.requestId) {
        this.$router.push({ name: 'provisional-remedy' });
        return;
      }
      
      try {
        await _removeRequest(this.requestId);
      } catch(err) {
        this.alertHandler(err.response.data.message || err.message);
      } finally {
        this.$router.push({ name: 'provisional-remedy' });
      }
    },
    async onNextHandler() {
      const isValid = await this.validateCurrentStep();
      if (!isValid) return;

      await this.saveHandler();
    },
    async saveHandler() {
      const currentStep = this.$refs.currentStepRef;
      if (currentStep?.saveStep == null) {
        this.setCompleteOption(this.currentStepIndex);
        this.currentStepIndex += 1;
        return;
      }

      currentStep.saveStep();

      if(this.currentStepIndex !== StepsIndex.CONFIRM_INFO) {
        this.setCompleteOption(this.currentStepIndex);
        this.currentStepIndex += 1;
        return;
      }

      try {
        const { data: request } = await _saveRequest(this.step1Payload);
        this.requestInfo.requestNumber = request.requestNumber;

        if(this.step2Payload && this.step2Payload.length) {
          this.step2Payload = this.step2Payload.map((item) => ({ ...item, requestId: request.requestId }));
        }
        await _saveOrUpdateServices(this.step2Payload);

        this.step3Payload = { ...this.step3Payload, requestId: request.requestId };
        await _finishRequest(this.step3Payload);
        
        this.currentStepIndex += 1;
        this.setCompleteOption(this.currentStepIndex);
      } catch (err) {
        this.alertHandler(err.response.data.message || err.message);
      }
    },
    async validateCurrentStep() {
      const currentStep = this.$refs.currentStepRef;
      if (currentStep.validate == null) return true;

      const validation = await currentStep.validate();
      if (!validation.valid) {
        this.alertHandler(
          validation.msg ||
            'Favor de completar la información solicitada para poder continuar.'
        );
      }
      return validation.valid;
    },
    alertHandler(msg, type = null) {
      this.ShowToast('', msg, type || 'error');
    },
    setCurrentRequestTab(to, next) {
      const stepIndex = to?.params?.stepIndex;
      if (stepIndex) {
        const tabStepIndex = stepIndex - 1;
        this.currentStepIndex = tabStepIndex;
        for (let idx = 0; idx <= tabStepIndex; idx++) {
          this.setActiveOption(idx);
        }
      }
      next();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/colors.scss';

::v-deep .content {
  &__section-title {
    margin-bottom: 1.8rem !important;
    &__info {
      margin-top: 0 !important;
    }
    &__container {
      background: map-get($colors, 'orange-light');
      display: inline-flex;
      align-items: center;
      padding: 0.1rem 1.2rem 0.1rem 0.4rem !important;
      .dot {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.actions {
  margin-top: 1rem;
  .action-btn {
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
  }
}
</style>
